<template>
  <section class="index-wrap">
    <section class="operation-wrap">
      <div class="title"><span></span>功能入口</div>
      <div class="operation-group">
        <template v-if="roles == 'tenant' && scopeType == 'village'">
          <div class="operation-item-wrap" @click="handleLink('community')">
            <img :src="require('@src/assets/community.png')" />
            <div class="name">社区填报</div>
          </div>

          <div class="operation-item-wrap" @click="handleLink('subAccount')" v-if="userinfo.accountGrade == 'main'">
            <img :src="require('@src/assets/user.png')" />
            <div class="name">子账户管理</div>
          </div>
        </template>

        <div class="operation-item-wrap" @click="handleLink('county')" v-if="scopeType == 'county'">
          <img :src="require('@src/assets/county.png')" />
          <div class="name">县（市、区）核准</div>
        </div>
        <div class="operation-item-wrap" @click="handleLink('city')" v-if="scopeType == 'city'">
          <img :src="require('@src/assets/score.png')" />
          <div class="name">市级评分</div>
        </div>
        <div class="operation-item-wrap" @click="handleLink('province')" v-if="scopeType == 'province'">
          <img :src="require('@src/assets/score.png')" />
          <div class="name">省级评审</div>
        </div>
        <div class="operation-item-wrap" @click="handleLink('user')" v-if="roles == 'admin'">
          <img :src="require('@src/assets/user.png')" />
          <div class="name">用户管理</div>
        </div>
      </div>
    </section>
    <!-- <section class="index-line"></section>
    <section class="notice-wrap"></section> -->
    <Info v-if="visibleModalByInfo" @onOk="visibleModalByInfo = false" @onCancel="visibleModalByInfo = false" />
    <User v-if="visibleModalByUser" @onOk="visibleModalByUser = false" @onCancel="visibleModalByUser = false" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { apiGetUserExist, apiGetUserExistOther } from '@src/apis';
import Info from './components/info';
import User from './components/user';

export default {
  name: '',
  components: { Info, User },
  data() {
    return {
      visibleModalByInfo: false,
      visibleModalByUser: false
    };
  },
  computed: {
    ...mapGetters(['userinfo']),
    roles() {
      return this.userinfo.roles[0];
    },
    scopeType() {
      let { regionCode } = this.userinfo;
      let scopeType = '';
      if (regionCode) {
        const len = regionCode.length;
        switch (len) {
          case 1:
            scopeType = 'zg';
            break;
          case 2:
            scopeType = 'province';
            break;
          case 4:
            scopeType = 'city';
            break;
          case 6:
            scopeType = 'county';
            break;
          case 9:
            scopeType = 'town';
            break;
          case 12:
            scopeType = 'village';
            break;
          default:
            break;
        }
      }
      return scopeType;
    }
  },
  created() {
    if (this.roles == 'tenant' && this.scopeType == 'village' && this.userinfo.accountGrade == 'main') {
      this.handleExist();
    } else if (this.scopeType != 'village' && this.roles != 'admin') {
      this.handleExistOther();
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['setActiveMenu']),
    handleLink(type) {
      let path = '';
      switch (type) {
        case 'community':
          path = '/quota';
          break;
        case 'subAccount':
          path = '/subAccount';
          break;
        case 'county':
          path = '/countyExamine';
          break;
        case 'city':
          path = '/cityExamine';
          break;
        case 'province':
          path = '/provinceExamine';
          break;
        case 'user':
          path = '/userManagements';
          break;

        default:
          break;
      }
      this.setActiveMenu({ path: path, name: '', query: {} });
    },
    /*
     *社区判断是否第一次
     */
    async handleExist() {
      try {
        const _this = this;

        const { code, data } = await apiGetUserExist(_this.userinfo.id);

        if (code === '00000') {
          if (!data) {
            _this.visibleModalByInfo = true;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    //省市区判断是否第一次
    async handleExistOther() {
      try {
        const _this = this;

        const { code, data } = await apiGetUserExistOther();

        if (code === '00000') {
          if (!data) {
            _this.visibleModalByUser = true;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
