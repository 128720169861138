<template>
  <div ref="newModal">
    <a-modal
      :getContainer="() => $refs.newModal"
      title="完善账号信息"
      visible
      :width="1000"
      :footer="null"
      :maskClosable="false"
      :keyboard="false"
      :closable="false"
    >
      <a-spin :spinning="spinning" tip="加载中" size="large">
        <section class="layout">
          <a-form :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }">
            <a-form-item label="用户地区" v-if="regions">
              <span v-for="(item, index) in regions" :key="index">
                {{ item.regionName }}
                <template v-if="index != regions.length - 1">-></template>
              </span>
            </a-form-item>
            <a-form-item label="登录账号">{{ userinfo.username }}</a-form-item>
          </a-form>
          <ZgyxFormGirdEdit
            ref="girdFormEdit"
            :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18 }"
            :formData="formState"
            :configs="[
              { span: 24, type: 'input', label: '申报单位名称', key: 'orgName', rules: [{ required: true }] },
              { span: 24, type: 'input', label: '单位负责人', key: 'orgPrincipal', rules: [{ required: true }] },
              {
                span: 24,
                type: 'input',
                label: '手机号码',
                key: 'orgMobile',
                rules: [
                  { required: true, message: '请输入联系电话' },
                  { pattern: regExpCheckPhone, message: '手机号格式不正确' }
                ]
              },
              { span: 24, type: 'input', label: '传真', key: 'orgFax', rules: [{ required: false }] },
              /*{
                span: 24,
                type: 'input',
                label: '电子邮箱',
                key: 'orgEmail',
                rules: [
                  { required: false, message: '请输入电子邮箱' },
                  { pattern: regExpCheckEmail, message: '邮箱格式不正确' }
                ]
              },
              {
                span: 24,
                type: 'textarea',
                label: '详细地址',
                key: 'orgAddress',
                showCount: true,
                maxlength: 200,
                autoSize: { minRows: 6 },
                rules: [{ required: false }]
              },
              {
                span: 24,
                type: 'textarea',
                label: '账号信息',
                key: 'introduce',
                showCount: true,
                maxlength: 2000,
                autoSize: { minRows: 6 },
                rules: [{ required: true }]
              },*/
              {
                span: 24,
                type: 'password',
                label: '原密码',
                key: 'passwordOld',
                rules: [{ required: true }]
              },
              {
                span: 24,
                type: 'password',
                label: '新密码',
                key: 'passwordNew',
                rules: [{ required: true, validator: checkPassword }]
              },
              {
                span: 24,
                type: 'password',
                label: '确认新密码',
                key: 'passwordConfirm',
                rules: [{ required: true, validator: checkPassword }]
              }
            ]"
            @update:formData="(formData) => (formState = formData)"
          >
          </ZgyxFormGirdEdit>
          <section style="margin: 0 auto; width: 100px">
            <a-button type="primary" @click="onOk" block>保存</a-button>
          </section>
        </section>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
import { ZgyxFormGirdEdit } from '@src/components';
import { apiGetUserInfo, apiPostSaveUserInfo } from '@src/apis';
import { regExpCheckPhone, regExpCheckEmail } from '@src/configs';
import { createJSEncryptPassword } from '../../utils';

const checkPassword = (rule, value, callback) => {
  const reg =
    /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,}$/;
  if (!value) {
    return Promise.reject('请输入密码');
  } else if (!reg.test(value)) {
    return Promise.reject('请输入至少8位并同时包含大写字母、小写字母、数字、符号中的其中三种的密码');
  } else {
    return Promise.resolve();
  }
};
export default {
  name: '',
  components: { ZgyxFormGirdEdit },
  props: {},
  data() {
    const formState = {
      orgName: '',
      orgPrincipal: '',
      orgMobile: '',
      orgFax: '',
      orgEmail: '',
      orgAddress: '',
      introduce: '',
      passwordOld: '',
      passwordNew: '',
      passwordConfirm: ''
    };
    return {
      formState,
      spinning: false,
      regions: [],
      checkPassword
    };
  },
  computed: {
    ...mapGetters(['dictionaries', 'userinfo']),
    // 手机号格式
    regExpCheckPhone() {
      return regExpCheckPhone;
    },
    regExpCheckEmail() {
      return regExpCheckEmail;
    }
  },
  created() {},
  mounted() {
    const _this = this;
    _this.$nextTick(async () => {
      _this.handleGetInfo();
    });
  },
  methods: {
    /*
     *获取详情
     */
    async handleGetInfo() {
      try {
        const _this = this;
        const { code, data } = await apiGetUserInfo(_this.userinfo.id);

        if (code === '00000' && data) {
          _this.formState = Object.assign({}, _this.formState, data ?? {});

          _this.regions = _this.formState.regions;
        }
      } catch (error) {
        console.error(error);
      }
    },
    /*
     * 更新
     */
    async handleUpdate() {
      try {
        const _this = this;
        const { formState } = this;
        let sendData = Object.assign({}, formState);

        sendData.passwordOld = createJSEncryptPassword(sendData.passwordOld);
        sendData.passwordNew = createJSEncryptPassword(sendData.passwordNew);
        const { code, msg } = await apiPostSaveUserInfo(sendData);
        if (code === '00000') {
          message.success(msg ?? '成功');
          _this.$emit('onOk');
        } else {
          // message.error(msg ?? '失败');
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击确定
     */
    async onOk() {
      try {
        const _this = this;
        const { isOk } = await _this.$refs.girdFormEdit.validate();

        if (_this.formState.passwordNew === _this.formState.passwordConfirm) {
          if (isOk) {
            _this.handleUpdate();
          }
        } else {
          message.error('两次密码不一致');
          return false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击取消
     */
    onCancel() {
      try {
        const _this = this;
        _this.$emit('onCancel');
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.layout {
  height: 66vh;
  padding: 20px;
  overflow-y: auto;
}
</style>
